import React from "react";
import PropTypes from "prop-types";
import SectionHeading from "../SectionHeading";
import Card from "wonderbly-components/lib/Card";
import { localiseUrlII } from "src/common/utils/format-localised-url";
import { useCountry } from "src/client/js/view/hooks";

import "./ProductTiles.scss";

const ProductTiles = (props) => {
  const country = useCountry();

  return (
    <section aria-labelledby="ProductTiles" className="ProductTiles">
      {props.title && (
        <SectionHeading tag="h2" textAlign="left">
          {props.title}
        </SectionHeading>
      )}
      {props.description && (
        <div className="ProductTiles__description container-guttered">
          {props.description}
        </div>
      )}
      <div className="container-guttered">
        <div className="ProductTiles__tiles-container">
          {props.items.map((item, idx) => (
            <Card
              key={idx}
              title={item.productTile.title}
              body={item.productTile.productBadgeContent}
              image={item.productTile.image.url}
              imageBackgroundColour="kraft-paper"
              href={
                item.productTile.href
                  ? localiseUrlII(country, item.productTile.href)
                  : null
              }
              cta={item.ctaLabel}
              showProductTags={props.showProductTags}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

ProductTiles.defaultProps = {
  showProductTags: false,
};

ProductTiles.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  items: PropTypes.array,
  showProductTags: PropTypes.bool,
};

export default ProductTiles;
